<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center p-3">
          <i class="ti-arrow-left icon clickable-item-hov" @click="dismissModal" />
          <strong class="text-black mx-auto" style="font-size: 18px">Background Overlay</strong>

          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close icon clickable-item-hov" style="font-size: 18px; color: lightslategray" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div v-if="isLoading">
        <ChLoading size="md" class="spinner mx-auto" />
      </div>
      <div v-else>
        <UserVirtualItems
          :config="{
            title: 'Your Background Overlays',
            shopTitle: 'Background Overlay Shop',
            featuredText: 'Featured Background Overlay',
            itemGroupTitle: 'Background Overlay',
            removeText: 'Remove Background Overlay',
          }"
          :virtualItem="virtualItem"
          @selected="userVirtualItem"
          @tryOn="dismissModal"
        />
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar" style="--min-height: 76px">
        <div class="d-flex justify-content-between actions px-3">
          <ion-custom-button color="medium" class="action-btn" @click="dismissModal">Cancel</ion-custom-button>
          <ion-custom-button color="primary" class="mr-2 action-btn" @click.self="save" :disabled="isSaving"
            >Save</ion-custom-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import UserVirtualItems from '@/shared/pages/profile/components/UserVirtualItems.vue';
import { getVirtualItemDetails } from '~/shared/actions/virtualItems';
import { authStore } from '~/shared/pinia-store/auth';
import { characterStylizeStore } from '~/shared/pinia-store/character-stylize';
import { profileStore } from '~/shared/pinia-store/profile';
import { Character } from '~/shared/types/static-types';
const props = defineProps({
  isOpen: {
    type: Boolean,
  },
  character: {
    type: Object as PropType<Character>,
  },
});
const isSaving = ref(false);
const { postUserProfileVirtualItem } = profileStore();
const { changeCharacterCustomizationData, currentTemplate } = characterStylizeStore();
const { user } = authStore();
const emits = defineEmits(['close', 'save']);
const isLoading = ref(true);
const character = toRef(props, 'character');
const virtualItem: any = ref({});
const isOpen = toRef(props, 'isOpen');
const fetchProfileBackgroundOverlay = async () => {
  const backgroundOverlay = get(user.value, 'customize_profile.background_overlay');
  if (!isEmpty(character.value)) {
    virtualItem.value = get(currentTemplate.value, `data.background_overlay`);
  } else if (backgroundOverlay && backgroundOverlay.id) {
    const { extra, title } = await getVirtualItemDetails(backgroundOverlay.id);
    virtualItem.value = { ...backgroundOverlay, extra, title };
    if (isEmpty(virtualItem.value.extra)) {
      virtualItem.value.extra = { bgRepeat: true };
    }
  }
  isLoading.value = false;
};

watch(isOpen, async () => {
  if (isOpen.value) {
    await fetchProfileBackgroundOverlay();
  }
});
const dismissModal = () => {
  emits('close');
};
const userVirtualItem = (ring: any) => {
  const id = get(ring, 'virtual_item') || ring;
  virtualItem.value = id;
};

const save = async () => {
  isSaving.value = true;
  let payload: any = {};
  const iconExists = get(virtualItem.value, 'icon');
  const username = user.value.username;
  payload = iconExists
    ? {}
    : character.value
    ? {
        id: virtualItem.value.id,
        image: virtualItem.value.image,
        extra: !isEmpty(get(virtualItem.value, 'extra')) ? virtualItem.value.extra : { bgRepeat: true },
      }
    : { id: virtualItem.value.id };

  if (character.value) {
    changeCharacterCustomizationData('background_overlay', payload);
  } else {
    await postUserProfileVirtualItem(payload, 'Background Overlay');
  }
  emits('save');

  isSaving.value = false;
};
</script>

<style lang="sass" scoped>
.icon
  font-size: 18px
  color: lightslategray
.profile-picture-container
  width: 100px
  height: 100px
  position: absolute
  left: 12px
  bottom: 12px
.camera-icon-pos
  bottom: 11px
  right: 26px
  z-index: 3
.round-button
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  --padding-start: 0
  --padding-end: 0
  width: 24px
  z-index: 12
  height: 24px
  position: absolute
.max-w-s
  max-width: 150px
.dark .toolbar
  --background: #17074c !important
.badge-lock
  border-radius: 20px
  height: 25px
  width: 25px
  background-color: grey
  display: flex
  align-items: center
  justify-content: center
  color: white
.set-direction
  @media(max-width: 576px)
    display: flex
    flex-direction: column-reverse
.indexer
  z-index: 2
.dimmed
  z-index: 1
  width: 100%
  height: 100%
  background-color: rgba(0,0,0,0.6)
  pointer-events: none
  top: 0
  left: 0

.cover-wrap
  border-radius: 12px

.header
  background: white

.dark .header
  background: unset

.user-cover-image
  height: 140px
  width: 100%
  overflow: hidden
  position: relative
  img
    width: 100%
    height: 100%
    border-radius: 12px
    background-position: center
    background-size: cover
    object-fit: cover

.round-cover-button
  --padding-start: 9px
  --padding-end: 9px
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  top: 9px
  right: 12px
  height: 24px
  font-size: 12px
  cursor: pointer
  z-index: 4


.modal-inner-content
  background: var(--ion-background-color, #fff)
  overflow: auto
  max-height: 70vh
  max-height: 70dvh

.modal-big
  --width: 705px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.social-media-button
  ::v-deep
    path
      fill: #FFF
ion-radio
  width: 30px
  height: 30px

ion-radio::part(container)
  border-radius: 8px
  border: 2px solid #ddd

ion-radio::part(mark)
  background: none
  transition: none
  transform: none
  border-radius: 0

ion-radio.radio-checked::part(container)
  background: #6815ec
  border-color: transparent

ion-radio.radio-checked::part(mark)
  width: 6px
  height: 10px
  border-width: 0px 2px 2px 0px
  border-style: solid
  border-color: #fff
  transform: rotate(45deg)
.color
  &:not(.selected)
    cursor: pointer
.modal-big
  font-size: 14px
.color
  border-radius: 20px
  height: 75px
  width: 40px
  &.selected
    border: 3px solid #999
.title
  font-weight: 700
  font-size: 16px !important
  color: #214163


.social-media-button
  min-width: 33px
  width: 33px
  height: 33px
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  visibility: visible !important

.c-input
  height: 35px !important
  max-height: 35px !important

.action-btn
  width: 90px !important
  margin-top: 0 !important
  ::v-deep
    *
      text-transform: unset !important
    .button
      height: 40px !important

.username
  border: 1px solid black
  border-radius: 12px

.gradient
    background-size: 200% auto !important
    animation: animatedTextGradient 5s linear infinite

@keyframes animatedTextGradient
  to
    background-position: 200% center
</style>
