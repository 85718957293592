<template>
  <div class="container" :style="`background-image: url(${animation})`"></div>
  <div class="title">
    {{ get(item, 'icon') ? 'None Selected' : get(item, 'title', 'None Selected') }}
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  item: {
    type: Object,
    default: {},
  },
});
const item = toRef(props, 'item');
const animation = computed(() => {
  return get(item.value, 'image');
});
</script>
<style lang="sass" scoped>
.title
  font-size: 16px
.container
  width: 150px
  height:150px
  border-radius: 20px
  border: 2px solid #e6e6e6
  background-size: cover
  background-repeat: no-repeat
</style>
