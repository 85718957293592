<template>
  <div class="d-flex flex-column">
    <div class="p-2 section">
      <div class="d-flex align-items-center mx-auto justify-content-center flex-column">
        <UserProfileBackgroundOverlayAnimation :item="virtualItem" />
      </div>
    </div>
    <div class="p-3" :style="'margin-top:185px'">
      <div class="title text-black py-2">{{ get(config, 'title') }}</div>
      <ChLoading size="lg" class="mx-auto" v-if="userLoader" />
      <ItemSection v-else :items="userVirtualItems" @selected="itemSelection" :virtualItem="virtualItem" />
    </div>
    <div class="p-3 shop">
      <div class="title">{{ get(config, 'shopTitle') }}</div>
      <CurrencyBar :setNoPadding="true" :coins="get(usersCurrency, 'coins')" :gems="get(usersCurrency, 'gems')" />
      <div class="title">{{ get(config, 'featuredText') }}</div>
      <ChLoading size="lg" class="mx-auto" v-if="loader" />
      <ItemSection
        v-else
        :items="featuredItems"
        :showWhiteBackground="true"
        :showShopBtn="true"
        :prfaddOns="true"
        :amountSection="true"
        :showTryon="true"
        @updated="fetchUserItems"
        @fetch="fetchFeaturedItems"
        @tryOn="tryOnBgOverlay"
        :selectedUserChar="selectedUserChar"
        :shopDetails="{
          name: 'Go to the Background Overlay Shop',
          emptyShopMessage:
            'No featured background overlays available. Visit Background Overlay Shop for more options.',
          itemGroupTitle: 'Background Overlay',
        }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import ItemSection from './VirtualItemsSection.vue';
import CurrencyBar from '@/shared/pages/store/CurrencyBar.vue';
import { getFeaturedVirtualItems, getUsersVirtualEarnings } from '@/shared/actions/virtualItems';
import { nanoid } from 'nanoid';
import { toast } from '~/shared/native';
import UserProfileBackgroundOverlayAnimation from './UserProfileBackgroundOverlayAnimation.vue';
const { usersCurrency, user, getUsersCurrency } = authStore();

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  virtualItem: {
    type: Object,
    default: {},
  },
});
const loader = ref(false);
const config = toRef(props, 'config');
const userLoader = ref(false);

const featuredItems = ref([]);
const userVirtualItems = ref([{ id: nanoid(8), title: `${get(config.value, 'removeText')}`, icon: true }]);
const prfRingImage: any = ref(null);
const emits = defineEmits(['selected', 'userchar', 'tryOn']);
const selectedUserChar = ref(user.value);
const tryOnBgOverlay = () => {
  emits('tryOn');
};
const fetchFeaturedItems = async () => {
  loader.value = true;
  try {
    const resp = await getFeaturedVirtualItems({ item_group__title: get(config.value, 'itemGroupTitle') });
    featuredItems.value = resp.filter(
      (val: any) => !get(val, 'in_store_till') || new Date().getTime() <= new Date(get(val, 'in_store_till')).getTime()
    );
    loader.value = false;
  } catch (error) {
    await toast.show('Error fetching featured items', 'nonative', 'danger');
    loader.value = false;
  }
};

const fetchUserVirtualItems = async () => {
  userLoader.value = true;
  userVirtualItems.value = [{ id: nanoid(8), title: `${get(config.value, 'removeText')}`, icon: true }];
  const resp = await getUsersVirtualEarnings({ item_group__title: get(config.value, 'itemGroupTitle') });
  userVirtualItems.value = userVirtualItems.value.concat(resp);
  userLoader.value = false;
};

const itemSelection = (ring: any) => {
  const resp = get(ring, 'virtual_item.image', ring.image);
  prfRingImage.value = resp;
  emits('selected', ring);
};

const fetchUserItems = () => {
  fetchFeaturedItems();
  fetchUserVirtualItems();
  getUsersCurrency();
};

onMounted(() => {
  fetchUserItems();
});
</script>

<style lang="sass" scoped>
.char-padding
  padding: 2px 13px 2px 13px !important
.container
  display: flex
  align-items: center
  justify-content: space-between
  padding: 2px 3px 2px 13px
  border: 1px solid #e6e6e6
  border-radius: 20px
  width: fit-content
.username
  font-size: 16px
.dropdown-icon
  display: flex
  align-items: center
  justify-content: center
  width: 24px
  height: 24px
  background-color: #214163
  border-radius: 50%
  cursor: pointer
.icon
  color: white
  font-size: 12px
  font-weight: bold
.shop
  background: linear-gradient(rgb(214, 190, 255) 0%, rgb(213 201 255) 49%, rgb(210 225 255) 100%)
.dark .shop
  background: none
.pfp
  width: 70px
  height: 70px
.circle-border
  width: 50px
  height: 50px
  border-radius: 24px
  border: 4px solid
.section
  background-color: white
  box-shadow:  rgba(0, 0, 0, 0.4) 0px 0px 3px, rgba(0, 0, 0, 0.3) 0px 7px 13px -6px, rgba(0, 0, 0, 0.2) 0px 1px 7px inset
  width: 100%
  z-index: 64
  position: fixed
  margin-top: 0px !important
.dark .section
  background-color: #17074C
.title
  font-weight: 900
  font-size: 18px
</style>
